import { Modal, Button } from "react-bootstrap";
import { ModalProps } from "../../shared/components/modalProps";

const GeneralModal = ({
  show,
  onHide,
  header,
  modalButtonProps,
  children,
}: ModalProps) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>{header}</Modal.Header>
    <Modal.Body className="show-grid">{children}</Modal.Body>
    {modalButtonProps && (
      <Modal.Footer>
        <Button variant={modalButtonProps.variant} onClick={modalButtonProps.onModalButtonClick}>
          {modalButtonProps.modalButtonText}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

export default GeneralModal;
