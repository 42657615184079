import { Container } from "react-bootstrap";

const Owner = () => (
  <Container className="mt-3">
    <p>
      <b>Owner</b>
    </p>
    <p>¡Qué rico! is a project developed by the following actors:</p>
    <p>
      <div>Patrik Huber - Gmunden</div>
      <div>Lorenz Kothmayr - Wien</div>
      <div>Dominik Wieser - Linz</div>
    </p>
  </Container>
);

export default Owner;
