import { Unit } from "./general/unit";
import { UnitType } from "./general/unitType";
import { UnitWithType } from "./general/unitWihtType"; 

export const gToKg = 1000;
export const mlToL = 1000;

export const ozToLb = 16;
export const lbToSt = 14;
export const flozToPt = 16;

export const metricMassUnits: Unit[] = [Unit.g, Unit.kg];
export const metricVolumeUnits: Unit[] = [Unit.ml, Unit.l];

export const imperialMassUnits: Unit[] = [Unit.oz, Unit.lb, Unit.st];
export const imperialVolumeUnits: Unit[] = [Unit.floz, Unit.pt];

export const otherUnits: Unit[] = [Unit.piece, Unit.pinch, Unit.dash];

export const metricUnits: UnitWithType[] = [
  {
    type: UnitType.mass,
    units: metricMassUnits
  },
  {
    type: UnitType.volume,
    units: metricVolumeUnits
  },
  {
    type: UnitType.other,
    units: otherUnits
  }
];

export const impoerialUnits: UnitWithType[] = [
  {
    type: UnitType.mass,
    units: imperialMassUnits
  },
  {
    type: UnitType.volume,
    units: imperialVolumeUnits
  },
  {
    type: UnitType.other,
    units: otherUnits
  }
];

export const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

export const locations = {
  eu: "EU",
  anglosphere: "ANGLOSPHERE",
  latinAmerica: "LATIN_AMERICA"
};

export const minServings = 1;
export const maxServings = 100;

export const muiColor = "#343434";

export const backendBaseUrl = process.env.NODE_ENV === "production"
  ? "https://backend.que-rico.net"
  : "http://localhost:8080";

export const recipesPerPage = 12;