import { Link } from "react-router-dom";

type MailToProps = {
  mailTo: string;
  label?: string;
};

const MailTo = ({ mailTo, label }: MailToProps) => {
  const onMailClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    window.location.href = `mailto:${mailTo}`;
  };

  return (
    <Link to="#" onClick={onMailClick}>
      {label ? label : mailTo}
    </Link>
  );
};

export default MailTo;
