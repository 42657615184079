import { Image } from "react-bootstrap";
import { muiColor } from "../../shared/constants";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

type EmployeeProps = {
  imageSource?: string;
};

const OptionalImage = ({ imageSource }: EmployeeProps) => (
  <>
    {imageSource && <Image src={imageSource} roundedCircle width="150" />}
    {!imageSource && (
      <QuestionMarkIcon sx={{ fontSize: 150, color: muiColor }} />
    )}
  </>
);

export default OptionalImage;
