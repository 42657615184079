import { Row, Col, Alert, Container } from "react-bootstrap";
import CenteredSpinner from "../../general/CenteredSpinner";
import Pagination from "../../general/Pagination";
import InternalServerError from "../../errors/InternalServerError";
import { recipesPerPage } from "../../../shared/constants";
import RecipeList from "./RecipeList";
import { RecipeOutput } from "../../../shared/output-types/recipeOutput";

type RecipeSearchProps = {
  currentPage: number;
  setCurrentPage: (value: number) => void;
  recipesCount?: number;
  recipes: RecipeOutput[];
  isLoading: boolean;
  isError: boolean;
  alertMessage: string;
  isEditMode: boolean;
};

const Recipes = ({
  currentPage,
  setCurrentPage,
  recipesCount,
  recipes,
  isLoading,
  isError,
  alertMessage,
  isEditMode
}: RecipeSearchProps) => {
  return (
    <Container className="mt-4">
      {isError && <InternalServerError />}
      {isLoading && !isError && <CenteredSpinner />}
      {!isLoading && !isError && recipes.length === 0 && (
        <Row className="p-2 mb-5">
          <Alert key="warning" variant="warning">
            {alertMessage}
          </Alert>
        </Row>
      )}
      {!isLoading && !isError && recipes.length !== 0 && (
        <RecipeList recipes={recipes} isEditMode={isEditMode} />
      )}
      <Row>
        <Col className="d-flex flex-row justify-content-center">
          <Pagination
            itemsCount={recipesCount ? recipesCount : 1}
            itemsPerPage={recipesPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            alwaysShown={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Recipes;
