import Card from "react-bootstrap/Card";
import styled from "styled-components";
import {sizes} from "../../shared/constants";

const FixedSizeCardImage = styled(Card.Img)`
  height: 200px;
  cursor: pointer;
  object-fit: cover;
  @media (max-width: ${sizes.md}px) {
    height: auto;
  }
`;

export default FixedSizeCardImage;
 