import {
  Container,
  Nav,
  Navbar,
  Form,
  Button,
  NavDropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "./../images/icon-white.svg";
import RootRoutes from "../navigation/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import WarningModal from "./modal/WarningModal";

const Header = () => {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const executeSearch = (searchParam: string) => {
    if (!search) {
      setShowWarningModal(true);
    } else {
      navigate(RootRoutes.AllRecipes, {
        state: {
          search: searchParam,
        },
      });
    }
  };

  const onClickSearch = () => {
    executeSearch(search);
  };

  const onSearchEnter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    executeSearch(search);
  };

  return (
    <>
      <WarningModal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        header="The search is blank"
      >
        <p>
          Please use a search term. We have many delicious recipes. Try to
          filter for them with terms like "salmon". The only important thing is
          that the serach doesn't stay empty.
        </p>
      </WarningModal>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <LinkContainer to={RootRoutes.Index}>
            <Navbar.Brand>
              <img
                src={icon}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
              ¡Qué rico!
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to={RootRoutes.Index}>
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Cookbook" id="navbarScrollingDropdown">
                {isAuthenticated && (
                  <LinkContainer to={RootRoutes.AddRecipe}>
                    <NavDropdown.Item>Add recipe</NavDropdown.Item>
                  </LinkContainer>
                )}
                <LinkContainer to={RootRoutes.AllRecipes}>
                  <NavDropdown.Item>All recipes</NavDropdown.Item>
                </LinkContainer>
                {isAuthenticated && (
                  <LinkContainer to={RootRoutes.MyRecipes}>
                    <NavDropdown.Item>My recipes</NavDropdown.Item>
                  </LinkContainer>
                )}
              </NavDropdown>
              <NavDropdown title="About" id="navbarScrollingDropdown">
                <LinkContainer to={RootRoutes.AboutUs}>
                  <NavDropdown.Item>About us</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={RootRoutes.Imprint}>
                  <NavDropdown.Item>Imprint</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              {!isAuthenticated && (
                <Nav.Link onClick={() => loginWithRedirect()}>
                  Login/Sign up
                </Nav.Link>
              )}
              {isAuthenticated && (
                <LinkContainer to={RootRoutes.Profile}>
                  <Nav.Link>Profile</Nav.Link>
                </LinkContainer>
              )}
              {isAuthenticated && (
                <Nav.Link
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </Nav.Link>
              )}
            </Nav>
            <Form className="d-flex" onSubmit={(event) => onSearchEnter(event)}>
              <Form.Control
                src={search}
                type="search"
                placeholder="Search for recipes"
                className="me-2"
                aria-label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
              <Button
                variant="outline-success"
                onClick={onClickSearch}
                disabled={!search}
              >
                Search
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
