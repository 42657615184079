import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FormikErrors, FormikTouched } from "formik";
import { RecipeProps } from "../../../shared/components/recipeProps";
import {
  useFetchCuisineTypesQuery,
  useFetchRecipeTypesQuery,
} from "../../../features/recipe-api/recipeApiSlice";

type RecipeDetailsFormProps = {
  values: RecipeProps;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  errors: FormikErrors<RecipeProps>;
  touched: FormikTouched<RecipeProps>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const RecipeDetailsFrom = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}: RecipeDetailsFormProps) => {
  const { data: recipeTypes = [] } = useFetchRecipeTypesQuery({});
  const { data: cuisines = [] } = useFetchCuisineTypesQuery({});

  return (
    <>
      <Row>
        <h4>Recipe details</h4>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Form.Group controlId="preparationTime" className="mt-3">
            <Form.Label>Preparation time</Form.Label>
            <Form.Control
              type="time"
              placeholder="preparation time"
              value={values.preparationTime}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.preparationTime}
              isValid={touched.preparationTime && !errors.preparationTime}
            />
            <Form.Text className="text-muted">
              Think about how long the preperation steps for the recipe will
              take in the format hh:mm (24h).
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.preparationTime}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group controlId="totalTime" className="mt-3">
            <Form.Label>Total time</Form.Label>
            <Form.Control
              type="time"
              placeholder="total time"
              value={values.totalTime}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.totalTime}
              isValid={touched.totalTime && !errors.totalTime}
            />
            <Form.Text className="text-muted">
              How long will your recipe take in total (prepertaion time and
              cooking time).
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.totalTime}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Form.Group controlId="author" className="mt-3">
            <Form.Label>Author</Form.Label>
            <Form.Control
              type="text"
              placeholder="author"
              value={values.author}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.author}
              isValid={touched.author && !errors.author}
            />
            <Form.Text className="text-muted">
              Insert your full name or your artist name.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.author}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group controlId="servings" className="mt-3">
            <Form.Label>Servings</Form.Label>
            <Form.Control
              type="number"
              placeholder="servings"
              value={values.servings}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.servings}
              isValid={touched.servings && !errors.servings}
            />
            <Form.Text className="text-muted">
              For how many people is your recipe?
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.servings}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Form.Group controlId="recipeTypes" className="mt-3">
            <Form.Label>Recipe types</Form.Label>
            <Form.Select
              multiple
              value={values.recipeTypes}
              onChange={(event) => {
                setFieldValue(
                  "recipeTypes",
                  Array.from(event.target.selectedOptions).map(
                    (selectedOption) => selectedOption.value
                  )
                );
              }}
              onBlur={handleBlur}
              isInvalid={!!errors.recipeTypes}
              isValid={touched.recipeTypes && !errors.recipeTypes}
            >
              {[...recipeTypes].sort((rt1, rt2) => {
                return rt1.name.localeCompare(rt2.name);
              }).map((recipeType, index) => {
                return (
                  <option key={index} value={recipeType.name}>
                    {recipeType.name}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Text className="text-muted">
              Select all categories that describe your recipe the best.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.recipeTypes as string}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} sm={12}>
          <Form.Group controlId="cuisine" className="mt-3">
            <Form.Label>Cuisine</Form.Label>
            <Form.Select
              value={values.cuisine}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.cuisine}
              isValid={touched.cuisine && !errors.cuisine}
            >
              {[...cuisines].sort((c1,c2) => {
                return c1.name.localeCompare(c2.name);
              }).map((cuisine, index) => {
                return (
                  <option key={index} value={cuisine.name}>
                    {cuisine.name}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Text className="text-muted">
              Select the cuisine this recipe is the most connected with.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.cuisine as string}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default RecipeDetailsFrom;
