import { Container, Row, Col } from "react-bootstrap";
import Employee from "./Employee";
import patrik from "../../../images/patrik.jpeg";
import lorenz from "../../../images/lorenz.jpg";

const Stuff = () => (
  <Container className="mt-5">
    <Row>
      <h3>Who are we?</h3>
      <hr />
    </Row>
    <Row>
      <p>
        In a 1984 interview with Newsweek, Steve Jobs famously described the
        computer as a “bicycle for the mind.” He was referring to the Apple Lisa
        computer, one of the first personal computers with a graphical user
        interface and a mouse. What Steve Jobs underlined is that progress must
        always serve people. Our goal is to help people to live healthier lives
        with the help of technology. For us, the focus is on showing that
        healthy food can be prepared quickly and cheaply. With this in mind, our
        cookbook is and will always be free. We often hear from the news about
        artificial intelligence and social media in a negative sense. We at ¡Qué
        rico! show that seemingly dangerous technologies can make people's lives
        easier when used for their benefit. Delicious food, whether for allergy
        sufferers, vegans, vegetarians, strength athletes or other people with
        special needs. With !Qué rico!, artificial intelligence works for you.
        AI doesn't put professional athletes and dietologists out of work
        writing diet plans at ¡Qué rico! either. Rather, it helps to speed up
        and support their work.
      </p>
      <p>
        Until now you didn't have a picutre in mind who ¡Qué rico! actually is.
        This will now change. ¡Qué rico! is a team of dedicated software
        engineers and founders who introduce themselves below:
      </p>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4}>
        <Employee
          className="m-3"
          imageSource={patrik}
          name="Patrik Huber"
          jobDescription="Software Engineer & Founder"
        />
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Employee
          className="m-3"
          imageSource={lorenz}
          name="Lorenz Kothmayr"
          jobDescription="Software Engineer & Founder"
        />
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Employee
          className="m-3"
          name="Dominik Wieser"
          jobDescription="Senior Software Engineer"
        />
      </Col>
    </Row>
  </Container>
);

export default Stuff;
