import { Container } from "react-bootstrap";

const GeneralDeclaration = () => (
  <Container>
    <p>
      <b>General declaration</b>
    </p>
    <p>
      Through the following disclosures, ¡Qué rico! complies with all
      requirements under §25 (2) of the Austrian Media Act.
    </p>
  </Container>
);

export default GeneralDeclaration;
