import AllRecipesHeader from "./AllRecipesHeader";
import AllRecipesContent from "./AllRecipesContent";
import { useLocation } from "react-router-dom";
import AllRecipesSearchContent from "./AllRecipesSearchContent";

const AllRecipesPage = () => {
  const location = useLocation();

  return (
    <>
      <AllRecipesHeader />
      {location?.state?.search ? (
        <AllRecipesSearchContent search={location?.state?.search} />
      ) : (
        <AllRecipesContent />
      )}
    </>
  );
};

export default AllRecipesPage;
