import { useAuth0 } from "@auth0/auth0-react";
import GeneralError from "./GeneralError";
import { Button } from "react-bootstrap";

const UnauthorizedError = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <GeneralError
      errorCode="401"
      headline="Oh no!"
      teaser="Are you logged in?"
      detailedDescription="Don't worry just use our Login/Sign up to get started with ¡Qué rico!"
    >
      <Button onClick={() => loginWithRedirect()}>Login/Sign up</Button>
    </GeneralError>
  );
};

export default UnauthorizedError;
