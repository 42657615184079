import { Container, Row } from "react-bootstrap";
import RootRoutes from "../../../navigation/routes";
import Navigation from "../../general/Navigation";

const MyRecipesHeader = () => (
  <Container className="mt-4">
    <Row>
      <Navigation
        steps={[
          { path: RootRoutes.Index, name: "Home", active: false },
          {
            name: "My recipes",
            active: true,
          },
        ]}
      />
    </Row>
    <Row>
      <h2>My recipes</h2>
    </Row>
    <hr />
  </Container>
);

export default MyRecipesHeader;
