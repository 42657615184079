import { useLocation, useParams } from "react-router-dom";
import LoadedRecipe from "../../recipe/LoadedRecipe";
import RootRoutes from "../../../../navigation/routes";
import InternalServerError from "../../../errors/InternalServerError";

const AllRecipesItemPage = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <>
      {id && (
        <LoadedRecipe
          id={id}
          servings={location?.state?.recipeServings}
          backlink={{ name: "All recipes", path: RootRoutes.AllRecipes }}
          recipeNewlyAdded={location?.state?.recipeNewlyAdded}
        />
      )}
      {!id && <InternalServerError />}
    </>
  );
};

export default AllRecipesItemPage;
