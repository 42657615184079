import { FormikErrors, FormikTouched } from "formik";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { RecipeProps } from "../../../shared/components/recipeProps";
import { useEffect, useRef, useState } from "react";

type ImageInputProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors: FormikErrors<RecipeProps>;
  touched: FormikTouched<RecipeProps>;
  value?: File;
};

const ImageInput = ({
  setFieldValue,
  errors,
  touched,
  value,
}: ImageInputProps) => {
  const inputRef = useRef(null);
  const [uploadState, setUploadState] = useState<string>("Upload a file");

  useEffect(() => {
    value && setFieldValue("image", value);
    value && setUploadState(`Uploaded image ${value.name}`);
  }, [value, setFieldValue, setUploadState]);

  const handleClick = () => {
    (inputRef?.current as any)?.click();
  };

  const imageInputField = (
    <>
      <Form.Control
        disabled={true}
        type="text"
        placeholder="Upload an image here"
        value={uploadState}
        isInvalid={!!errors.image}
        isValid={touched.image && !errors.image}
      />
      <Form.Text className="text-muted">
        Convince other people with a snapshot of your ready meal to give your
        recipe a try.
      </Form.Text>
      <Form.Control.Feedback type="invalid">
        {errors.image}
      </Form.Control.Feedback>
    </>
  );

  const impageInputButton = (
    <>
      <Button onClick={handleClick} className="w-100">
        Choose image
      </Button>
    </>
  );

  return (
    <>
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        accept=".jpg, .png, .jpeg"
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          target.files && setFieldValue("image", target.files[0]);
          target.files && setUploadState(`Uploaded image ${target.files[0].name}`);
        }}
      />
      <Form.Group controlId="recipeImage" className="mt-3">
        <Form.Label>Image</Form.Label>
        <Container className="p-0">
          <Row>
            <Col md={9} className="d-none d-md-block">
              {imageInputField}
            </Col>
            <Col md={3} className="d-none d-md-block">
              {impageInputButton}
            </Col>
          </Row>
          <Row>
            <Col className="d-block d-md-none mb-3">{imageInputField}</Col>
          </Row>
          <Row>
            <Col className="d-block d-md-none">{impageInputButton}</Col>
          </Row>
        </Container>
      </Form.Group>
    </>
  );
};

export default ImageInput;
