import Image from "react-bootstrap/Image";
import styled from "styled-components";

const FixedSizeCardImage = styled(Image)`
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
`;

export default FixedSizeCardImage;