import { Container, Row } from "react-bootstrap";
import DoneIcon from '@mui/icons-material/Done';
import { muiColor } from "../../shared/constants";
import GeneralModal from "./GeneralModal";
import { ModalProps } from "../../shared/components/modalProps";

const SuccessModal = ({
  show,
  onHide,
  header,
  modalButtonProps,
  children,
}: ModalProps) => (
  <GeneralModal
    show={show}
    onHide={onHide}
    header={header}
    modalButtonProps={modalButtonProps}
  >
    <Container className="text-center">
      <Row>
        <div>
          <DoneIcon sx={{ fontSize: 100, color: muiColor }} />
        </div>
      </Row>
      <Row className="mt-3">{children}</Row>
    </Container>
  </GeneralModal>
);

export default SuccessModal;