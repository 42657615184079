import { Avatar } from "@mui/material";
import { ReactNode } from "react";

type StepProps = {
  step: string;
  className?: string;
  children: ReactNode;
};

const Step = ({ step, className, children }: StepProps) => (
  <div
    className={`d-flex flex-row align-items-center justify-content-left ${className}`}
  >
    <Avatar>{step}</Avatar>
    <p className="m-3">{children}</p>
  </div>
);

export default Step;
