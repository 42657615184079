import { Row, Col } from "react-bootstrap";
import { RecipeOutput } from "../../../shared/output-types/recipeOutput";
import RecipeListItem from "./RecipeListItem";

type RecipeListProps = {
  recipes: RecipeOutput[];
  isEditMode: boolean;
};

const RecipeList = ({ recipes, isEditMode }: RecipeListProps) => (
  <>
    {recipes.length !== 0 && (
      <Row className="mb-5">
        {[...recipes]
          .sort(
            (r1, r2) =>
              r1.name.localeCompare(r2.name) || r1.id.localeCompare(r2.id)
          )
          .map((elem, index) => (
            <Col xxl={3} lg={4} md={6} sm={12} className="mt-5" key={index}>
              <RecipeListItem elem={elem} isEditMode={isEditMode} />
            </Col>
          ))}
      </Row>
    )}
  </>
);

export default RecipeList;
