import { useAuth0 } from "@auth0/auth0-react";
import UnauthorizedError from "../../errors/UnauthorizedError";
import AddRecipeHeader from "./AddRecipeHeader";
import AddRecipeContent from "./AddRecipeContent";
import CenteredSpinner from "../../general/CenteredSpinner";
import InternalServerError from "../../errors/InternalServerError";

const AddRecipePage = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  return (
    <>
      <AddRecipeHeader />
      {isLoading && !error && <CenteredSpinner />}
      {!isLoading && error && <InternalServerError />}
      {!isLoading && !error && !isAuthenticated && <UnauthorizedError />}
      {!isLoading && !error && isAuthenticated && <AddRecipeContent />}
    </>
  );
};

export default AddRecipePage;
