import { Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { scrollToTop } from "../helper/scrolling";
import logo from "../images/icon-black.svg";
import RootRoutes from "../navigation/routes";
import { useAuth0 } from "@auth0/auth0-react";
import HoverableParagraph from "./general/HoverableParagraph";

const Footer = () => {
  const { isAuthenticated } = useAuth0();
  const year = new Date().getFullYear();

  return (
    <footer className="container py-3 my-4">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <LinkContainer to={RootRoutes.Index} onClick={() => scrollToTop()}>
            <HoverableParagraph className="nav-link px-2 text-muted">
              Home
            </HoverableParagraph>
          </LinkContainer>
        </li>
        <li className="nav-item">
          <LinkContainer
            to={RootRoutes.AllRecipes}
            onClick={() => scrollToTop()}
          >
            <HoverableParagraph className="nav-link px-2 text-muted">
              Cookbook
            </HoverableParagraph>
          </LinkContainer>
        </li>
        {isAuthenticated && (
          <li className="nav-item">
            <LinkContainer
              to={RootRoutes.AddRecipe}
              onClick={() => scrollToTop()}
            >
              <HoverableParagraph className="nav-link px-2 text-muted">
                Add recipe
              </HoverableParagraph>
            </LinkContainer>
          </li>
        )}
        <li className="nav-item">
          <LinkContainer to={RootRoutes.AboutUs} onClick={() => scrollToTop()}>
            <HoverableParagraph className="nav-link px-2 text-muted">
              About
            </HoverableParagraph>
          </LinkContainer>
        </li>
        <li className="nav-item">
          <LinkContainer to={RootRoutes.Imprint} onClick={() => scrollToTop()}>
            <HoverableParagraph className="nav-link px-2 text-muted">
              Imprint
            </HoverableParagraph>
          </LinkContainer>
        </li>
      </ul>
      <div className="d-flex flex-column align-items-center">
        <p className="text-muted">© {year} ¡Qué rico!</p>
        <Image src={logo} height="32" />
      </div>
    </footer>
  );
};

export default Footer;
