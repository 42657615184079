import { Container } from "react-bootstrap";

const Responsiblity = () => (
  <Container className="mt-3">
    <p>
      <b>Responsibility</b>
    </p>
    <p>
      Our offer contains links to external websites of third parties, on whose
      contents we have no influence. Therefore, we cannot assume any liability
      for these external contents. The respective provider or operator of the
      pages is always responsible for the content of the linked pages. The
      linked pages were checked for possible legal violations at the time of
      linking. Illegal contents were not recognisable at the time of linking.
      However, permanent monitoring of the content of the linked pages is not
      reasonable without concrete indications of a legal violation. If we become
      aware of any infringements of the law, we will remove such links
      immediately.
    </p>
  </Container>
);

export default Responsiblity;
