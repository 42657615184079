import { useState } from "react";
import Recipes from "../recipe/Recipes";
import {
  useFetchCountOfRecipesQuery,
  useFetchPaginationRecipesQuery
} from "../../../features/recipe-api/recipeApiSlice";
import { recipesPerPage } from "../../../shared/constants";

const AllRecipesContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: recipesCount,
    isLoading: recipeCountIsLoading,
    isError: recipesCountIsError,
  } = useFetchCountOfRecipesQuery();
  const {
    data: recipes = [],
    isLoading: recipesIsLoading,
    isError: recipesIsError,
  } = useFetchPaginationRecipesQuery({
    amount: recipesPerPage,
    page: currentPage
  });

  return (
    <Recipes
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      recipesCount={recipesCount}
      recipes={recipes}
      isLoading={recipeCountIsLoading || recipesIsLoading}
      isError={recipesCountIsError || recipesIsError}
      alertMessage="Unfortunately there are currently no recipes available. Please try it later again."
      isEditMode={false}
    />
  );
};

export default AllRecipesContent;
