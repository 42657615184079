import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CuisineOutput } from "../../shared/output-types/cuisineOutput";
import { RecipeOutput } from "../../shared/output-types/recipeOutput";
import { RecipeTypeOutput } from "../../shared/output-types/recipeTypeOutput";
import { backendBaseUrl } from "../../shared/constants";
import { RecipeInput } from "../../shared/input-types/recipeInput";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: backendBaseUrl
  }),
  endpoints(builder) {
    return {
      fetchRecipeTypes: builder.query<RecipeTypeOutput[], { language?: string }>({
        query(arg) {
          const { language } = arg;
          return {
            url: "/recipe-type",
            params: { language }
          };
        },
      }),
      fetchCuisineTypes: builder.query<CuisineOutput[], { language?: string }>({
        query(arg) {
          const { language } = arg;
          return {
            url: "/cuisine",
            params: { language }
          };
        }
      }),
      fetchPaginationRecipes: builder.query<RecipeOutput[], { amount: number, page: number, location?: string, language?: string, servings?: number }>({
        query: (arg) => {
          const { amount, page, location, language, servings } = arg;
          return {
            url: "/recipe",
            params: { amount, page, location, language, servings }
          }
        }
      }),
      fetchPaginationRecipesBySearch: builder.query<RecipeOutput[], { search?: string, amount: number, page: number, location?: string, language?: string, servings?: number }>({
        query: (arg) => {
          const { search, amount, page, location, language, servings } = arg;
          return {
            url: `/recipe/search/${search}`,
            params: { amount, page, location, language, servings }
          }
        }
      }),
      fetchPaginationRecipesByExternalUserId: builder.query<RecipeOutput[], { externalUserId: string, amount: number, page: number, location?: string, language?: string, servings?: number }>({
        query: (arg) => {
          const { amount, page, externalUserId, location, language, servings } = arg;
          return {
            url: `/recipe/external-user-id/${encodeURIComponent(externalUserId)}`,
            params: { amount, page, location, language, servings }
          }
        }
      }),
      fetchRecipe: builder.query<RecipeOutput, { id: string, location?: string, language?: string, servings?: number }>({
        query(arg) {
          const { id, location, language, servings } = arg;
          return {
            url: `/recipe/${id}`,
            params: { location, language, servings }
          }
        }
      }),
      fetchCountOfRecipes: builder.query<number, void>({
        query() {
          return {
            url: "/recipe/count",
          }
        }
      }),
      fetchCountOfRecipesBySearch: builder.query<number, { search: string }>({
        query(arg) {
          const { search } = arg;
          return {
            url: `/recipe/count/search/${search}`
          }
        }
      }),
      fetchCountOfRecipesByExternalUserId: builder.query<number, { externalUserId: string }>({
        query(arg) {
          const { externalUserId } = arg;
          return {
            url: `recipe/count/external-user-id/${encodeURIComponent(externalUserId)}`
          }
        }
      }),
      addRecipe: builder.mutation<RecipeOutput, { recipe: RecipeInput, image: File, token: string }>({
        query(arg) {
          const { recipe, image, token } = arg;
          const formData = new FormData();
          formData.append("recipe", new Blob([JSON.stringify(recipe)], { type: "application/json" }));
          formData.append("image", image);
          return {
            url: "/recipe",
            method: "POST",
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData
          }
        }
      }),
      updateRecipe: builder.mutation<RecipeOutput, { recipe: RecipeInput, token: string, recipeId: string }>({
        query(arg) {
          const { recipe, token, recipeId } = arg;
          return {
            url: `/recipe/${recipeId}`,
            method: "PUT",
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: recipe
          }
        }
      }),
      upadateImage: builder.mutation<RecipeOutput, { image: File, token: string, recipeId: string }>({
        query(arg) {
          const { image, token, recipeId} = arg;
          const formData = new FormData();
          formData.append("image", image);
          return {
            url: `/recipe/image/${recipeId}`,
            method: "PUT",
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData
          }
        }
      }),
      deleteRecipe: builder.mutation<void, { id: string, token: string }>({
        query(arg) {
          const { id, token } = arg;
          return {
            url: `/recipe/${id}`,
            method: "DELETE",
            headers: {
              "Authorization": `Bearer ${token}`
            },
          }
        }
      })
    }
  }
});

export const {
  useFetchRecipeTypesQuery,
  useFetchCuisineTypesQuery,
  useFetchRecipeQuery,
  useFetchPaginationRecipesQuery,
  useFetchPaginationRecipesBySearchQuery,
  useFetchPaginationRecipesByExternalUserIdQuery,
  useFetchCountOfRecipesQuery,
  useFetchCountOfRecipesBySearchQuery,
  useFetchCountOfRecipesByExternalUserIdQuery,
  useAddRecipeMutation,
  useUpdateRecipeMutation,
  useUpadateImageMutation,
  useDeleteRecipeMutation
} = apiSlice;