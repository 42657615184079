import styled from "styled-components";

const HoverableParagraph = styled.p`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export default HoverableParagraph;
