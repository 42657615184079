import {
  useFetchRecipeTypesQuery,
  useFetchCuisineTypesQuery,
  useUpdateRecipeMutation,
  useUpadateImageMutation,
} from "../../../features/recipe-api/recipeApiSlice";
import CenteredSpinner from "../../general/CenteredSpinner";
import RecipeFormik from "../form/RecipeFormik";
import RootRoutes from "../../../navigation/routes";
import InternalServerError from "../../errors/InternalServerError";
import { useAuth0 } from "@auth0/auth0-react";
import { generatePath, useNavigate } from "react-router-dom";
import { useState } from "react";
import ErrorModal from "../../modal/ErrorModal";
import { RecipeToEditProps } from "../../../shared/components/recipeToEditProps";
import { scrollToTop } from "../../../helper/scrolling";
import { toRecipeInput } from "../../../shared/general/mapper";

type EditRecipeContentProps = {
  recipeToEdit: RecipeToEditProps;
};

const EditRecipeContent = ({ recipeToEdit }: EditRecipeContentProps) => {
  const { isLoading: recipeTypesAreLoading, isError: recipeTypesHaveError } =
    useFetchRecipeTypesQuery({});
  const { isLoading: cuisinesAreLoading, isError: cuisinesHaveError } =
    useFetchCuisineTypesQuery({});
  const { getAccessTokenSilently } = useAuth0();

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [updateRecipe] = useUpdateRecipeMutation();
  const [updateImage] = useUpadateImageMutation();

  const navigate = useNavigate();

  const isLoading: boolean = recipeTypesAreLoading || cuisinesAreLoading;
  const isError: boolean = recipeTypesHaveError || cuisinesHaveError;

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        onHide={(): void => setShowErrorModal(false)}
        header="Ooops we could't upload"
      >
        <p>
          We couldn't upload your recipe. Don't worry it isn't you. It is us and
          we will be back soon.
        </p>
      </ErrorModal>
      {isError && <InternalServerError />}
      {isLoading && !isError && <CenteredSpinner />}
      {!isLoading && !isError && (
        <RecipeFormik
          recipeToEdit={recipeToEdit}
          onSubmit={(values, { setSubmitting }) => {
            getAccessTokenSilently().then((token) => {
              Promise.all([
                updateRecipe({
                  recipe: toRecipeInput(values),
                  token,
                  recipeId: recipeToEdit.id,
                }).unwrap(),
                updateImage({
                  image: values.image as File,
                  token,
                  recipeId: recipeToEdit.id,
                }).unwrap(),
              ])
                .then((recipes) => {
                  setSubmitting(false);
                  navigate(
                    generatePath(RootRoutes.MyRecipesItem, { id: recipes[0].id })
                  );
                  scrollToTop();
                })
                .catch((_) => {
                  setSubmitting(false);
                  setShowErrorModal(true);
                });
            });
          }}
          submitButtonText="Save changes on recipe"
        />
      )}
    </>
  );
};

export default EditRecipeContent;
