import { Row, Col, ListGroup, Container } from "react-bootstrap";
import { User } from "@auth0/auth0-react";
import OptionalImage from "../general/OptionalImage";

type UserDataContentProps = {
  user: User;
};

const UserDataContent = ({ user }: UserDataContentProps) => {
  return (
    <Container>
      <Row>
        <Col md={12} lg={4} className="mb-5">
          <h4>About you</h4>
          <p>
            ¡Qué rico! is proud to have you as a member of our platform. You are
            one of those people on this planet who have realized that cooking is
            connected with passion. Until now, no one but ¡Qué rico! could meet
            your high demands for a cookbook. With ¡Qué rico! you found exactly
            that and have been using it ever since. We want to thank you for
            that and hope to have you as our member for a long time to come.
          </p>
        </Col>
        <Col
          className="d-flex flex-column align-items-center justify-content-center mb-5"
          md={12}
          lg={4}
        >
          <OptionalImage imageSource={user.picture} />
        </Col>
        <Col md={12} lg={4} className="mb-5">
          <h4>Account details</h4>
          <ListGroup variant="flush">
            {user.name && (
              <ListGroup.Item key="name">Name: {user.name}</ListGroup.Item>
            )}
            {user.nickname && (
              <ListGroup.Item key="nickname">
                Nickname: {user.nickname}
              </ListGroup.Item>
            )}
            {user.website && (
              <ListGroup.Item key="website">
                website: {user.website}
              </ListGroup.Item>
            )}
            {user.email && (
              <ListGroup.Item key="email">Email: {user.email}</ListGroup.Item>
            )}
            {user.birthdate && (
              <ListGroup.Item key="birthdate">
                Birthdate: {user.birthdate}
              </ListGroup.Item>
            )}
            {user.phone_number && (
              <ListGroup.Item key="phoneNumber">
                Phone number: {user.phone_number}
              </ListGroup.Item>
            )}
            {user.address && (
              <ListGroup.Item key="address">
                Address: {user.address}
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDataContent;
