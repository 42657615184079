import { flozToPt, gToKg, lbToSt, mlToL, ozToLb } from "../shared/constants";
import { Unit } from "../shared/general/unit";
import { IngredientOutput } from "../shared/output-types/ingredientOutput";
import { RecipeOutput } from "../shared/output-types/recipeOutput";

export const caclulateFor = (changedServings: number, baseRecipe: RecipeOutput): IngredientOutput[] =>
  baseRecipe.ingredients.map(i => ({
    ...i,
    amount: Math.round(i.amount / baseRecipe.servings * changedServings),
  })).map(i => convertAmountAndUnitFor(i));

const convertAmountAndUnitFor = (ingredient: IngredientOutput): IngredientOutput => ({
  ...ingredient,
  ...recaculate(ingredient.amount, ingredient.unit)
});

const recaculate = (amount: number, unit: Unit): { amount: number, unit: Unit } => {
  if (hasCorrectUnitForAmount(amount, unit)) {
    return { amount, unit };
  }

  if (unit === Unit.g && amount >= gToKg) {
    return { amount: amount / gToKg, unit: Unit.kg };
  }

  if (unit === Unit.kg && amount < 1) {
    return { amount: amount * gToKg, unit: Unit.g };
  }

  if (unit === Unit.ml && amount >= mlToL) {
    return { amount: amount / mlToL, unit: Unit.l };
  }

  if (unit === Unit.l && amount < 1) {
    return { amount: amount * mlToL, unit: Unit.ml };
  }

  if (unit === Unit.floz && amount >= flozToPt) {
    return { amount: amount / flozToPt, unit: Unit.pt };
  }

  if (unit === Unit.pt && amount < 1) {
    return { amount: amount * flozToPt, unit: Unit.floz };
  }

  if (unit === Unit.oz && amount >= ozToLb) {
    recaculate(amount / ozToLb, Unit.lb);
  }

  if (unit === Unit.lb && amount < 1) {
    recaculate(amount * ozToLb, Unit.oz);
  }

  if (unit === Unit.lb && amount >= lbToSt) {
    recaculate(amount / lbToSt, Unit.st);
  }

  if (unit === Unit.st && amount < 1) {
    recaculate(amount * lbToSt, Unit.lb);
  }

  return { amount, unit };
};

const hasCorrectUnitForAmount = (amount: number, unit: Unit): boolean => {
  switch (unit) {
    case Unit.g: return amount < gToKg;
    case Unit.kg: return amount >= 1;
    case Unit.ml: return amount < mlToL;
    case Unit.l: return amount >= 1;
    case Unit.floz: return amount < flozToPt;
    case Unit.pt: return amount >= 1;
    case Unit.oz: return amount < ozToLb;
    case Unit.lb: return amount >= 1 && amount < lbToSt;
    case Unit.st: return amount >= 1;
    default: return true;
  }
}