import { ReactNode } from "react";
import DetailsButton from "../../general/DetailsButton";
import { Form } from "react-bootstrap";

type RecipeDetailsSectionProp = {
  header: string;
  value: string;
  children: ReactNode;
};

const RecipeDetailsSection = ({
  header,
  value,
  children,
}: RecipeDetailsSectionProp) => (
  <div className="d-flex flex-row align-items-center justify-content-left w-100 mb-2">
    <DetailsButton variant="dark" disabled>
      {children} <span>{header}</span>
    </DetailsButton>
    <Form.Control value={value} disabled />
  </div>
);

export default RecipeDetailsSection;
