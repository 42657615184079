import { ReactNode } from "react";
import { Button, Spinner } from "react-bootstrap";

type SubmitButtonProps = {
  className?: string;
  isSubmitting: boolean;
  children: ReactNode;
};

const SubmitButton = ({
  className,
  isSubmitting,
  children,
}: SubmitButtonProps) => (
  <Button
    type="submit"
    variant="success"
    disabled={isSubmitting}
    className={`mt-5 mb-5 ${className}`}
  >
    {isSubmitting && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}
    {isSubmitting && " "}
    <span>{children}</span>
  </Button>
);

export default SubmitButton;
