import { Container, Row } from "react-bootstrap";
import Navigation from "../../general/Navigation";
import RootRoutes from "../../../navigation/routes";

const ImprintHeader = () => (
  <Container className="mt-4">
    <Row>
      <Navigation
        steps={[
          { path: RootRoutes.Index, name: "Home", active: false },
          { name: "Imprint", active: true },
        ]}
      />
    </Row>
    <Row>
      <h2>Imprint</h2>
    </Row>
    <hr />
  </Container>
);

export default ImprintHeader;
