type ExplanationProps = {
  className?: string;
};

const Explanation = ({ className }: ExplanationProps) => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center ${className}`}
  >
    <div className="w-75 text-center">
      <h3 className="mb-3">What is our mission?</h3>
      <p>
        ¡Qué rico! is Spanish and means very tasty. Individual nutrition is
        becoming increasingly important. More and more people have allergies or
        other reasons why they need to change their diet. Yet we want nothing
        more than to eat delicious food. ¡Qué rico! is a smart cookbook for
        everyone and 100% ad-free. If a recipe is missing, simply add it via
        photo. Let a dietologist of your choice create a diet plan for you with
        the help of our recipe collection, or create it yourself.
      </p>
    </div>
  </div>
);

export default Explanation;
