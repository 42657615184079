import { CuisineOutput } from "../../../shared/output-types/cuisineOutput";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { useFetchCuisineTypesQuery } from "../../../features/recipe-api/recipeApiSlice";
import { RecipeProps } from "../../../shared/components/recipeProps";
import RecipeForm from "./RecipeForm";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "react-bootstrap";

const FILE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const addRecipeValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("A recipe name needs to be longer than two letters."),
  description: Yup.string()
    .trim()
    .required("A recipe description needs to be longer than two letters."),
  preparationTime: Yup.string()
    .required("Please provide a preperation time.")
    .matches(
      /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/,
      "Please use the time format hh:mm."
    ),
  totalTime: Yup.string()
    .required("Please provide a total time.")
    .matches(
      /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/,
      "Please use the time format hh:mm."
    ),
  author: Yup.string()
    .trim()
    .required("Please enter the name of the author of the recipe."),
  servings: Yup.number()
    .required("Please enter a number for servings.")
    .min(1, "Please enter a number which is greater or equal 1"),
  recipeTypes: Yup.array().min(1, "Please select at least one recipe type."),
  cuisine: Yup.mixed().test(
    "cuisineSelected",
    "No cuisine is selected.",
    (value) =>
      value && (value as CuisineOutput).name !== "Please select an element"
  ),
  ingredients: Yup.array().min(1, "Please enter at least one ingredient."),
  notes: Yup.array().notRequired(),
  prerequisites: Yup.array().notRequired(),
  instructions: Yup.array().min(1, "Please enter at least one instruction."),
  image: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      `The image is too large. The maximum size is ${FILE_SIZE / 1024 / 1024} MB.`,
      (value) => value && (value as File).size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported format.",
      (value) => value && SUPPORTED_FORMATS.includes((value as File).type)
    ),
});

type RecipeFormikProps = {
  recipeToEdit?: RecipeProps;
  onSubmit: ((
    values: RecipeProps,
    formikHelpers: FormikHelpers<RecipeProps>
  ) => void | Promise<any>) &
    ((values: RecipeProps, formikHelpers: FormikHelpers<RecipeProps>) => void);
  submitButtonText: string;
};

const RecipeFormik = ({
  recipeToEdit,
  onSubmit,
  submitButtonText,
}: RecipeFormikProps) => {
  const { data: cuisines = [] } = useFetchCuisineTypesQuery({});
  const { user } = useAuth0();

  const initialValue: RecipeProps = recipeToEdit
    ? recipeToEdit
    : {
        name: "",
        description: "",
        preparationTime: "",
        totalTime: "",
        author: user?.name ? user.name : "",
        servings: 0,
        recipeTypes: [],
        cuisine: cuisines[0].name,
        ingredients: [],
        notes: [],
        prerequisites: [],
        instructions: [],
        image: undefined,
      };

  return (
    <Container>
      <Formik
        validationSchema={addRecipeValidationSchema}
        initialValues={initialValue}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <RecipeForm
            submitButtonText={submitButtonText}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        )}
      </Formik>
    </Container>
  );
};

export default RecipeFormik;
