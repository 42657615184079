import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { FormControlElement } from "../../../shared/components/formControlElement";

type ListInputProps = {
  className: string;
  label: string;
  buttonText: string;
  placeholder: string;
  description: string;
  fieldName: string;
  values: string[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isInvalid: boolean;
  isValid: boolean | undefined;
  feedback?: string | never[] | string[] | undefined;
};

const ListInput = ({
  className,
  label,
  buttonText,
  placeholder,
  description,
  fieldName,
  values,
  setFieldValue,
  isInvalid,
  isValid,
  feedback,
}: ListInputProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [currentValue, setCurrentValue] = useState<string>("");

  const currentElementOnChange = (
    event: React.ChangeEvent<FormControlElement>
  ): void => {
    if (event.target.value.length === 0) {
      setDisabled(true);
    }

    if (event.target.value.length > 0) {
      setDisabled(false);
    }

    setCurrentValue(event.target.value);
  };

  const onClickAdd = (): void => {
    setFieldValue(fieldName, [...values, currentValue]);
    setCurrentValue("");
    setDisabled(true);
  };

  const onClickRemove = (index: Number): void => {
    setFieldValue(
      fieldName,
      values.filter((_, i) => i !== index)
    );
  };

  const listInputField = (
    <>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={currentValue}
        onChange={(event) => currentElementOnChange(event)}
        isInvalid={isInvalid}
        isValid={isValid}
      />
      <Form.Text className="text-muted">{description}</Form.Text>
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </>
  );
  const listInputButton = (
    <Button onClick={onClickAdd} disabled={disabled} className="w-100">
      {buttonText}
    </Button>
  );

  return (
    <Form.Group controlId={fieldName} className={className}>
      <Form.Label>{label}</Form.Label>
      <Container className="p-0">
        {values.map((value, index) => (
          <Row key={index}>
            <Col md={11} className="mb-3">
              <Form.Control type="text" value={value} readOnly disabled />
            </Col>
            <Col md={1} className="mb-3">
              <Button
                variant="danger"
                onClick={() => onClickRemove(index)}
                className="w-100"
              >
                -
              </Button>
            </Col>
          </Row>
        ))}
        <Row>
          <Col md={9} className="d-none d-md-block">
            {listInputField}
          </Col>
          <Col md={3} className="d-none d-md-block">
            {listInputButton}
          </Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none mb-3">{listInputField}</Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none">{listInputButton}</Col>
        </Row>
      </Container>
    </Form.Group>
  );
};

export default ListInput;
