import { Container } from "react-bootstrap";
import Step from "../../general/Step";

const Description = () => (
  <Container className="mt-5">
    <h3>What is our goal?</h3>
    <hr />
    <p>
      We at ¡Qué rico! want to be honest with you. It's never easy to change
      your diet. It doesn't get any easier with us either. But giving up is no
      solution either. You have a goal and it is important to pursue it. We
      would like to support you in the best possible way with a diet plan. How
      do you benefit from ¡Qué rico! is described below:
    </p>
    <Step step="1.">
      Find a ready-made nutrition plan from our fitness coaches or dietologists.
    </Step>
    <Step step="2.">
      Make an appointment with one of our dietologists who will craete the
      perfect nutrition plan for you.
    </Step>
    <Step step="3.">
      ¡Qué rico! tracks your calories according to your plan. You just need to
      follow the plan or choose an equivalent meal.
    </Step>
    <Step step="4.">
      With our free cookbook, it's easy to cook the dish in your nutrition plan.
      The calories are automatically tracked.
    </Step>
    <Step step="5.">
      You can't follow your plan this time? We also help you find an equivalent
      dish in a restaurant if you don't plan to cook tonight.
    </Step>
  </Container>
);

export default Description;
