import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import slogan from "../../images/slogan.png";
import RootRoutes from "../../navigation/routes";

type QuoteProps = {
  className?: string;
};

const Quote = ({ className }: QuoteProps) => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center ${className}`}
  >
    <img src={slogan} className="w-50 m-3" alt="Slogan of que-rico" />
    <div className="m-3 w-75">
      <blockquote className="blockquote text-center">
        <cite>
          Eat your food as your medicines. Otherwise, you will have to eat
          medicines as your food.
        </cite>
        <footer className="blockquote-footer mt-2">Ratan Tata</footer>
      </blockquote>
    </div>
    <LinkContainer to={RootRoutes.AllRecipes} className="m-3">
      <Button variant="outline-dark">Discover our cookbook</Button>
    </LinkContainer>
  </div>
);

export default Quote;
