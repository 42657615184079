import { useLocation } from "react-router-dom";
import EditRecipeContent from "./EditRecipeContent";
import { useAuth0 } from "@auth0/auth0-react";
import EditRecipeHeader from "./EditRecipeHeader";
import CenteredSpinner from "../../general/CenteredSpinner";
import InternalServerError from "../../errors/InternalServerError";
import UnauthorizedError from "../../errors/UnauthorizedError";

const EditRecipePage = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  const location = useLocation();

  return (
    <>
      <EditRecipeHeader />
      {isLoading && !error && <CenteredSpinner />}
      {!isLoading && error && <InternalServerError />}
      {!isLoading && !error && !isAuthenticated && <UnauthorizedError />}
      {!isLoading && !error && isAuthenticated && (
        <EditRecipeContent recipeToEdit={location?.state?.recipeToEdit} />
      )}
    </>
  );
};

export default EditRecipePage;
