import { Container } from "react-bootstrap";

const Copyright = () => (
  <Container className="mt-3">
    <p>
      <b>Copyright</b>
    </p>
    <p>
      ¡Qué rico! grants permission to use and copy all content appearing on
      these Internet pages for the purpose of obtaining information for the user
      or to make a printout. For commercial use, this only applies after prior
      written consent has been granted.
    </p>
  </Container>
);

export default Copyright;
