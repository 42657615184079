import { Button } from "react-bootstrap";
import styled from "styled-components";

const DetailsButton = styled(Button)`
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 20px;
`;

export default DetailsButton;