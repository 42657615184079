import { useState } from "react";
import { useFetchCountOfRecipesByExternalUserIdQuery, useFetchPaginationRecipesByExternalUserIdQuery } from "../../../features/recipe-api/recipeApiSlice";
import { recipesPerPage } from "../../../shared/constants";
import Recipes from "../recipe/Recipes";

type MyRecipesContentProps = {
  externalUserId: string;
};

const MyRecipesContent = ({ externalUserId }: MyRecipesContentProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: recipesCount,
    isLoading: recipeCountIsLoading,
    isError: recipesCountIsError,
  } = useFetchCountOfRecipesByExternalUserIdQuery({ externalUserId });
  const {
    data: recipes = [],
    isLoading: recipesIsLoading,
    isError: recipesIsError,
  } = useFetchPaginationRecipesByExternalUserIdQuery({
    amount: recipesPerPage,
    page: currentPage,
    externalUserId,
  });
  const alertMessage = "Unfortunately you haven't uploaded recipes so far. It is never too late. What do you think about uploading a recipe right now?";

  return (
    <Recipes
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      recipesCount={recipesCount}
      recipes={recipes}
      isLoading={recipeCountIsLoading || recipesIsLoading}
      isError={recipesCountIsError || recipesIsError}
      alertMessage={alertMessage}
      isEditMode={true}
    />
  ); 
};

export default MyRecipesContent;