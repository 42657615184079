import Row from "react-bootstrap/Row";
import ListInput from "./ListInput";
import IngredientInput from "./IngredientInput";
import { FormikErrors, FormikTouched } from "formik";
import { RecipeProps } from "../../../shared/components/recipeProps";
import ImageInput from "./ImageInput";

type RecipeMainFormProps = {
  values: RecipeProps;
  errors: FormikErrors<RecipeProps>;
  touched: FormikTouched<RecipeProps>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const RecipeMainForm = ({
  values,
  errors,
  touched,
  setFieldValue,
}: RecipeMainFormProps) => (
  <>
    <Row>
      <h4>Main part</h4>
    </Row>
    <Row>
      <ListInput
        className="mt-3"
        label="Prerequisites"
        buttonText="Add prerequisite"
        placeholder="Preheat the oven to level 5."
        description="Preheating the oven in the end is too late. Add here the prerequisites."
        fieldName="prerequisites"
        values={values.prerequisites}
        setFieldValue={setFieldValue}
        isInvalid={!!errors.prerequisites}
        isValid={touched.prerequisites && !errors.prerequisites}
        feedback={errors.prerequisites as string}
      />
    </Row>
    <Row>
      <ListInput
        className="mt-3"
        label="Instructions"
        buttonText="Add instruction"
        placeholder="Cut the potatoes into cubes."
        description="Add all instructions needed for actually cooking the recipe."
        fieldName="instructions"
        values={values.instructions}
        setFieldValue={setFieldValue}
        isInvalid={!!errors.instructions}
        isValid={touched.instructions && !errors.instructions}
        feedback={errors.instructions as string}
      />
    </Row>
    <Row>
      <ListInput
        className="mt-3"
        label="Notes"
        buttonText="Add note"
        placeholder="Use the rest of the salmon to prepare a salad the next day."
        description="Add extra information to your recipe. It is optional to add notes to the recipe."
        fieldName="notes"
        values={values.notes}
        setFieldValue={setFieldValue}
        isInvalid={!!errors.notes}
        isValid={touched.notes && !errors.notes}
        feedback={errors.notes as string}
      />
    </Row>
    <Row>
      <IngredientInput
        className="mt-3"
        label="Ingredients"
        buttonText="Add ingredient"
        placeholderIngredient="Chopped potatoes"
        placeholderAmount="300"
        description="Add all ingredients to the list that are needed for this recipe."
        fieldName="ingredients"
        values={values.ingredients}
        setFieldValue={setFieldValue}
        isInvalid={!!errors.ingredients}
        isValid={touched.ingredients && !errors.ingredients}
        feedback={errors.ingredients as string}
      />
    </Row>
    <Row>
      <ImageInput
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        value={values.image}
      />
    </Row>
  </>
);

export default RecipeMainForm;
