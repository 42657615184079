import { Container, Row } from "react-bootstrap";
import { useFetchRecipeQuery } from "../../../features/recipe-api/recipeApiSlice";
import CenteredSpinner from "../../general/CenteredSpinner";
import Navigation from "../../general/Navigation";
import Recipe from "./Recipe";
import RecipeCreationSuccessAlert from "./RecipeCreationSuccessAlert";
import RootRoutes from "../../../navigation/routes";
import InternalServerError from "../../errors/InternalServerError";
import { Step } from "../../../shared/components/step";

type LoadedRecipeProps = {
  id: string;
  servings?: number;
  recipeNewlyAdded: boolean;
  backlink: Step;
};

const LoadedRecipe = ({
  id,
  servings,
  recipeNewlyAdded,
  backlink,
}: LoadedRecipeProps) => {
  const {
    data: recipe,
    isLoading,
    isError,
  } = useFetchRecipeQuery({ id, servings });

  return (
    <>
      <Container className="mt-4">
        {isError && <InternalServerError />}
        {!isError && isLoading && <CenteredSpinner />}
        {!isError && !isLoading && recipe && (
          <>
            <Row>
              <Navigation
                steps={[
                  { path: RootRoutes.Index, name: "Home", active: false },
                  {
                    path: backlink.path,
                    name: backlink.name,
                    active: false,
                  },
                  {
                    name: recipe.name,
                    active: true,
                  },
                ]}
              />
            </Row>
            {recipeNewlyAdded && (
              <Row>
                <RecipeCreationSuccessAlert />
              </Row>
            )}
          </>
        )}
      </Container>
      {!isError && !isLoading && recipe && <Recipe recipe={recipe} />}
    </>
  );
};

export default LoadedRecipe;
