import { Container, Row } from "react-bootstrap";
import Navigation from "../../general/Navigation";
import RootRoutes from "../../../navigation/routes";

const EditRecipeHeader = () => (
  <Container className="mt-4">
    <Row>
      <Navigation
        steps={[
          { path: RootRoutes.Index, name: "Home", active: false },
          {
            name: "Edit recipe",
            active: true,
          },
        ]}
      />
    </Row>
    <Row>
      <h2>Edit recipe</h2>
    </Row>
    <hr />
  </Container>
);

export default EditRecipeHeader;
