import ImprintHeader from "./ImprintHeader";
import Contact from "./Contact";
import Copyright from "./Copyright";
import GeneralDeclaration from "./GeneralDeclaration";
import FieldOfActivity from "./FieldOfActivity";
import Responsiblity from "./Responsibility";
import Owner from "./Owner";

const ImprintPage = () => (
  <>
    <ImprintHeader />
    <GeneralDeclaration />
    <FieldOfActivity />
    <Owner />
    <Contact />
    <Copyright />
    <Responsiblity />
  </>
);

export default ImprintPage;
