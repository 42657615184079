import Statement from "./Statement";
import Description from "./Description";

const HomePage = () => (
  <>
    <Statement className="mt-4" />
    <Description className="mt-4" />
  </>
);

export default HomePage;
