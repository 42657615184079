const enum RootRoutes {
  Index = "/",
  AllRecipes = "/cookbook/all-recipes",
  AllRecipesItem = "/cookbook/all-recipes/:id",
  AddRecipe = "/cookbook/add-recipe",
  EditRecipeItem = "/cookbook/edit-recipe/:id",
  MyRecipes = "/cookbook/my-recipes",
  MyRecipesItem = "/cookbook/my-recipes/:id",
  AboutUs = "/about/about-us",
  Imprint = "/about/imprint",
  Profile = "/profile"
}

export default RootRoutes;
