import { Container } from "react-bootstrap";
import MailTo from "../../general/MailTo";

const Contact = () => (
  <Container className="mt-3">
    <div>
      <p>
        <b>Contact</b>
      </p>
      <p>
        Getting in touch with ¡Qué rico! is very easy. We welcome any kind of
        input to further improve our product. Have we aroused your interest?
        Feel free to contact us by email. We offer the following email address:
      </p>
      <MailTo mailTo="que-rico-org@proton.me" />
    </div>
  </Container>
);

export default Contact;
