import { RecipeProps } from "../components/recipeProps";
import { RecipeInput } from "../input-types/recipeInput";

export const toRecipeInput = (recipeProps: RecipeProps): RecipeInput => ({
  name: recipeProps.name,
  description: recipeProps.description,
  preparationTime: recipeProps.preparationTime,
  totalTime: recipeProps.totalTime,
  author: { name: recipeProps.author },
  servings: recipeProps.servings,
  recipeTypes: recipeProps.recipeTypes.map((recipeType) => ({
    name: recipeType,
  })),
  cuisine: { name: recipeProps.cuisine },
  ingredients: recipeProps.ingredients.map((ingredient) => ({
    foodItem: {
      name: ingredient.foodItem.name,
    },
    amount: ingredient.amount,
    unit: ingredient.unit,
  })),
  notes: recipeProps.notes,
  prerequisites: recipeProps.prerequisites,
  instructions: recipeProps.instructions,
});