import icon from "./../../images/icon-black.svg";
import { ReactNode } from "react";

type GeneralErrorProps = {
  errorCode: string;
  headline: string;
  teaser: string;
  detailedDescription: string;
  children: ReactNode;
};

const GeneralError = ({
  errorCode,
  headline,
  teaser,
  detailedDescription,
  children
}: GeneralErrorProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <img
          src={icon}
          width="200"
          height="200"
          className="d-inline-block align-top"
          alt="logo"
        />
        <h1 className="display-1 fw-bold">{errorCode}</h1>
        <p className="fs-3">
          {" "}
          <span className="text-danger">{headline}</span> {teaser}
        </p>
        <p className="lead">
          {detailedDescription}
        </p>
          {children}
      </div>
    </div>
  );
};

export default GeneralError;
