import { useState } from "react";
import {
  useAddRecipeMutation,
  useFetchCuisineTypesQuery,
  useFetchRecipeTypesQuery,
} from "../../../features/recipe-api/recipeApiSlice";
import InternalServerError from "../../errors/InternalServerError";
import CenteredSpinner from "../../general/CenteredSpinner";
import ErrorModal from "../../modal/ErrorModal";
import RecipeFormik from "../form/RecipeFormik";
import { useAuth0 } from "@auth0/auth0-react";
import { generatePath, useNavigate } from "react-router-dom";
import RootRoutes from "../../../navigation/routes";
import { RecipeOutput } from "../../../shared/output-types/recipeOutput";
import { scrollToTop } from "../../../helper/scrolling";
import { toRecipeInput } from "../../../shared/general/mapper";

const AddRecipeContent = () => {
  const { isLoading: recipeTypesAreLoading, isError: recipeTypesHaveError } =
    useFetchRecipeTypesQuery({});
  const { isLoading: cuisinesAreLoading, isError: cuisinesHaveError } =
    useFetchCuisineTypesQuery({});
  const { getAccessTokenSilently } = useAuth0();

  const [addRecipe] = useAddRecipeMutation();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const isLoading: boolean = recipeTypesAreLoading || cuisinesAreLoading;
  const isError: boolean = recipeTypesHaveError || cuisinesHaveError;

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        onHide={(): void => setShowErrorModal(false)}
        header="Ooops we could't upload"
      >
        <p>
          We couldn't upload your recipe. Don't worry it isn't you. It is us and
          we will be back soon.
        </p>
      </ErrorModal>
      {isLoading && !isError && <CenteredSpinner />}
      {!isLoading && isError && <InternalServerError />}
      {!isLoading && !isError && (
        <RecipeFormik
          onSubmit={(values, { setSubmitting }) => {
            getAccessTokenSilently().then((token) => {
              addRecipe({
                recipe: toRecipeInput(values),
                image: values.image as File,
                token,
              })
                .unwrap()
                .then((recipe: RecipeOutput) => {
                  setSubmitting(false);
                  navigate(
                    generatePath(RootRoutes.AllRecipesItem, { id: recipe.id }),
                    {
                      state: {
                        recipeNewlyAdded: true,
                      },
                    }
                  );
                  scrollToTop();
                })
                .catch((_) => {
                  setSubmitting(false);
                  setShowErrorModal(true);
                });
            });
          }}
          submitButtonText="Add recipe to cookbook"
        />
      )}
    </>
  );
};

export default AddRecipeContent;
