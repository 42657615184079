import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { backendBaseUrl } from "../../../shared/constants";
import { RecipeOutput } from "../../../shared/output-types/recipeOutput";
import FixedSizeImage from "../../general/FixedSizeImage";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonIcon from "@mui/icons-material/Person";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import CategoryIcon from "@mui/icons-material/Category";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import RecipeDetailsSection from "./RecipeDetailsSection";
import RecipeDetailsServingsSection from "./RecipeDetailsServingsSection";
import { useState } from "react";
import { IngredientOutput } from "../../../shared/output-types/ingredientOutput";
import ErrorModal from "../../modal/ErrorModal";
import { caclulateFor } from "../../../helper/ingredientAmountCalculator";

type RecipeProps = {
  recipe: RecipeOutput;
};

const Recipe = ({ recipe }: RecipeProps) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [ingredients, setIngredients] = useState<IngredientOutput[]>(
    recipe.ingredients
  );

  const setIngredientsForChangedServings = (newServings: number) => {
    setIngredients(caclulateFor(newServings, recipe));
  };

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        header="Ooops"
      >
        It isn't you. It is us. For calculating the ingredient amount we need to
        reach the backend. It is currently not reachable but don't worry we
        already work on it.
      </ErrorModal>
      <Container>
        <Row>
          <h2 className="mb-3">{recipe.name}</h2>
        </Row>
        <hr />
        <Row>
          <Col xl={6} lg={12} className="mb-5">
            <h4>Details</h4>
            <RecipeDetailsSection
              header="Preparation Time"
              value={`${recipe.preparationTime} h`}
            >
              <TimelapseIcon />
            </RecipeDetailsSection>
            <RecipeDetailsSection
              header="Total Time"
              value={`${recipe.totalTime} h`}
            >
              <ScheduleIcon />
            </RecipeDetailsSection>
            <RecipeDetailsSection header="Author" value={recipe.author.name}>
              <PersonIcon />
            </RecipeDetailsSection>
            <RecipeDetailsServingsSection
              header="Servings"
              initialValue={recipe.servings}
              executeOnServingsChanged={setIngredientsForChangedServings}
            >
              <LocalPizzaIcon />
            </RecipeDetailsServingsSection>
            <RecipeDetailsSection
              header="Recipe Types"
              value={recipe.recipeTypes
                ?.map((recipeTypes) => recipeTypes.name)
                .join(", ")}
            >
              <CategoryIcon />
            </RecipeDetailsSection>
            <RecipeDetailsSection header="Cuisine" value={recipe.cuisine.name}>
              <SoupKitchenIcon />
            </RecipeDetailsSection>
          </Col>
          <Col xl={6} lg={12} className="mb-5">
            <FixedSizeImage
              src={`${backendBaseUrl}/image/${recipe.imageId}`}
              className="img-fluid"
              rounded
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={6} md={12} className="mb-5">
            <h4>Ingredients</h4>
            <ListGroup variant="flush">
              {[...ingredients]
                .sort((i1, i2) =>
                  i1.foodItem.name.localeCompare(i2.foodItem.name)
                )
                .map((e, index) => (
                  <ListGroup.Item key={index}>
                    {e.foodItem.name}: {e.amount} {e.unit}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
          <Col lg={6} md={12} className="mb-5">
            <h4>Instructions</h4>
            <ListGroup as="ol" numbered variant="flush">
              {recipe.instructions.map((e, index) => (
                <ListGroup.Item as="li" key={index}>
                  {e}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          {recipe.notes.length !== 0 && (
            <Col lg={6} md={12} className="mb-5">
              <h4>Notes</h4>
              <ListGroup as="ol" numbered variant="flush">
                {recipe.notes?.map((e, index) => (
                  <ListGroup.Item key={index}>{e}</ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          )}
          {recipe.prerequisites.length !== 0 && (
            <Col lg={6} md={12} className="mb-5">
              <h4>Prerequisites</h4>
              <ListGroup as="ol" numbered variant="flush">
                {recipe.prerequisites?.map((e, index) => (
                  <ListGroup.Item as="li" key={index}>
                    {e}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Recipe;
