import AboutUsHeader from "./AboutUsHeader";
import Description from "./Description";
import Roadmap from "./Roadmap";
import Stuff from "./Stuff";

const AboutUsPage = () => (
  <>
    <AboutUsHeader />
    <Description />
    <Stuff />
    <Roadmap />
  </>
);

export default AboutUsPage;
