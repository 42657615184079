import { Container, Row } from "react-bootstrap";
import Navigation from "../../general/Navigation";
import RootRoutes from "../../../navigation/routes";

const AboutUsHeader = () => (
  <Container className="mt-4">
    <Row>
      <Navigation
        steps={[
          { path: RootRoutes.Index, name: "Home", active: false },
          { name: "About us", active: true },
        ]}
      />
    </Row>
    <Row>
      <h2>About us</h2>
    </Row>
    <hr />
    <Row>
      <p>
        We at ¡Qué rico! have a dream. A balanced and healthy diet is one of the
        most important things for us humans.
      </p>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="m-3 w-75">
          <blockquote className="blockquote text-center">
            <cite>You become what you think. You are what you eat.</cite>
            <footer className="blockquote-footer mt-2">Barbara Cartland</footer>
          </blockquote>
        </div>
      </div>
      <p>
        However, when we talk about healthy food there is not one healthy food.
        For example, in this world more and more people have allergies. These
        people have to consider their allergies in their daily life. Strength
        athletes need a diet rich in fat and protein. We at ¡Qué rico! want to
        support exactly that. With us you will get an individually tailored diet
        that will help you achieve your goals.
      </p>
    </Row>
  </Container>
);

export default AboutUsHeader;
