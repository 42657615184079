import { ReactNode, useState } from "react";
import DetailsButton from "../../general/DetailsButton";
import { Form } from "react-bootstrap";
import { minServings, maxServings } from "../../../shared/constants";

const nomralizeServings = (servings?: number): number => {
  if (!servings || servings < minServings || servings > maxServings) {
    return 1;
  }

  return servings;
}

type RecipeDetailsServingSectionProps = {
  header: string;
  initialValue?: number;
  children: ReactNode;
  executeOnServingsChanged?: (newValue: number) => void;
};

const RecipeDetailsServingsSection = ({
  header,
  initialValue,
  children,
  executeOnServingsChanged,
}: RecipeDetailsServingSectionProps) => {
  const [currentServings, setCurrentServings] = useState<number>(nomralizeServings(initialValue));

  const onServingsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newServings = nomralizeServings(+event.target.value);
    if (executeOnServingsChanged) {
      executeOnServingsChanged(newServings);
    }

    setCurrentServings(newServings);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-left w-100 mb-2">
      <DetailsButton variant="dark" disabled>
        {children} <span>{header}</span>
      </DetailsButton>
      <Form.Control
        type="number"
        min={minServings}
        max={maxServings}
        value={currentServings}
        onChange={onServingsChanged}
        style={{
          flexBasis: "75px",
          flexGrow: 0,
          flexShrink: 0,
          marginRight: "20px",
        }}
      />
      <Form.Range
        min={minServings}
        max={maxServings}
        value={currentServings}
        onChange={onServingsChanged}
      />
    </div>
  );
};

export default RecipeDetailsServingsSection;
