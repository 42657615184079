import { useState } from "react";
import Recipes from "../recipe/Recipes";
import {
  useFetchCountOfRecipesBySearchQuery,
  useFetchPaginationRecipesBySearchQuery
} from "../../../features/recipe-api/recipeApiSlice";
import { recipesPerPage } from "../../../shared/constants";

type AllRecipesSearchContentProps = {
  search: string
}

const AllRecipesSearchContent = ({ search }: AllRecipesSearchContentProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: recipesCount,
    isLoading: recipeCountIsLoading,
    isError: recipesCountIsError,
  } = useFetchCountOfRecipesBySearchQuery({ search });
  const {
    data: recipes = [],
    isLoading: recipesIsLoading,
    isError: recipesIsError,
  } = useFetchPaginationRecipesBySearchQuery({
    amount: recipesPerPage,
    page: currentPage,
    search
  });

  return (
    <Recipes
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      recipesCount={recipesCount}
      recipes={recipes}
      isLoading={recipeCountIsLoading || recipesIsLoading}
      isError={recipesCountIsError || recipesIsError}
      alertMessage={`Unfortunately there are no recipes available for "${search}". Don't give up we have a lot of delicious recipes. Just try something else.`}
      isEditMode={false}
    />
  );
};

export default AllRecipesSearchContent;