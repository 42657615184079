import OptionalImage from "../../general/OptionalImage";

type EmployeeProps = {
  imageSource?: string;
  name: string;
  jobDescription: string;
  className?: string;
};

const Employee = ({
  imageSource,
  name,
  jobDescription,
  className,
}: EmployeeProps) => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center ${className}`}
  >
    <OptionalImage imageSource={imageSource} />
    <div className="mt-5">{name}</div>
    <div className="mt-1">{jobDescription}</div>
  </div>
);

export default Employee;
