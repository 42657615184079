import { Container } from "react-bootstrap";

const FieldOfActivity = () => (
  <Container className="mt-3">
    <p>
      <b>Field of activity</b>
    </p>
    <p>
      The field of activity of ¡Qué rico! is to support users in creating
      nutrition plans with artificial intelligence. Via the integrated cookbook,
      our users can prepare the dishes contained in the nutrition plans. Another
      field of activity of ¡Qué rico! is to support people with special dietary
      requirements or restrictions due to allergies with substitute products
      when cooking. The project is currently under development and no money is
      being made with it yet.
    </p>
  </Container>
);

export default FieldOfActivity;
