import React from "react";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import RootRoutes from "../../../navigation/routes";

const RecipeCreationSuccessAlert: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Alert key="success" variant="success">
        <Alert.Heading>
          ¡Qué rico! You successfully uploaded your recipe!
        </Alert.Heading>
        <p> A lot of people will enjoy it. Kudos to you.</p>

        <div className="d-flex justify-content-start">
          <Button
            onClick={() => {
              navigate(RootRoutes.AddRecipe);
            }}
            variant="outline-success"
          >
            Add another recipe
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default RecipeCreationSuccessAlert;
