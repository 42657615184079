import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "bootstrap/dist/css/bootstrap.min.css";
import RootRouterProvider from "./navigation/RootNavigation";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain="dev-1nwh5y51elah374n.us.auth0.com"
        clientId="93e6QjNTuUqjtotTQ2ubtRtGnTT93dPc"
        authorizationParams={{
          audience: "https://backend.que-rico.net",
          redirect_uri: window.location.origin,
        }}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <RootRouterProvider />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);
