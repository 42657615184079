import { Container, Row } from "react-bootstrap";
import Navigation from "../general/Navigation";
import RootRoutes from "../../navigation/routes";

const UserDataHeader = () => (
  <Container className="mt-4">
    <Row>
      <Navigation
        steps={[
          { path: RootRoutes.Index, name: "Home", active: false },
          { name: "Profile", active: true },
        ]}
      />
    </Row>
    <Row>
      <h2>Profile</h2>
    </Row>
    <hr />
  </Container>
);

export default UserDataHeader;