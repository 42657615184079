import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const StyledSpinner = styled(Spinner)`
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
  margin-top: -..px;
  margin-right: -..px;
`;

const CenteredSpinner = () => {
  return <StyledSpinner animation="grow" />;
}

export default CenteredSpinner;
