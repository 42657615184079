import { Form, Row, Col } from "react-bootstrap";
import RecipeGeneralForm from "./RecipeGeneralForm";
import RecipeDetailsFrom from "./RecipeDetailsForm";
import RecipeMainForm from "./RecipeMainForm";
import { RecipeProps } from "../../../shared/components/recipeProps";
import { FormikErrors, FormikTouched } from "formik";
import SubmitButton from "../../general/SubmitButton";

type RecipeFormProps = {
  submitButtonText: string;
  isSubmitting: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  values: RecipeProps;
  touched: FormikTouched<RecipeProps>;
  errors: FormikErrors<RecipeProps>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const RecipeForm = ({
  submitButtonText,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
}: RecipeFormProps) => (
  <Form noValidate onSubmit={handleSubmit}>
    <RecipeGeneralForm
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
    />
    <hr />
    <RecipeDetailsFrom
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
    />
    <hr />
    <RecipeMainForm
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
    />
    <Row>
      <Col>
        <SubmitButton className="mt-5 mb-5" isSubmitting={isSubmitting}>
          {submitButtonText}
        </SubmitButton>
      </Col>
    </Row>
  </Form>
);

export default RecipeForm;
