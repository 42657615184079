import { Container, Row, Col, Card, Button } from "react-bootstrap";
import FixedSizeCardImage from "../../general/FixedSizeCardImage";
import { RecipeOutput } from "../../../shared/output-types/recipeOutput";
import { backendBaseUrl } from "../../../shared/constants";
import { scrollToTop } from "../../../helper/scrolling";
import { useNavigate, generatePath } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import WarningModal from "../../modal/WarningModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteRecipeMutation } from "../../../features/recipe-api/recipeApiSlice";
import SuccessModal from "../../modal/SuccessModal";
import ErrorModal from "../../modal/ErrorModal";
import RootRoutes from "../../../navigation/routes";
import { urlToImage } from "../../../helper/fileOperations";

type RecipeListItemProps = {
  elem: RecipeOutput;
  isEditMode: boolean;
};

const RecipeListItem = ({ elem, isEditMode }: RecipeListItemProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showWaringModal, setShowWarningModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [deleteRecipe] = useDeleteRecipeMutation();
  const navigate = useNavigate();

  const handleOnGoToClick = (recipe: RecipeOutput) => {
    const path = isEditMode
      ? RootRoutes.MyRecipesItem
      : RootRoutes.AllRecipesItem;
    navigate(generatePath(path, { id: recipe.id }));
    scrollToTop();
  };

  const handleOnEditClick = (recipe: RecipeOutput) => {
    urlToImage(`${backendBaseUrl}/image/${recipe.imageId}`).then((image) => {
      navigate(generatePath(RootRoutes.EditRecipeItem, { id: recipe.id }), {
        state: {
          recipeToEdit: {
            ...recipe,
            author: recipe.author.name,
            recipeTypes: recipe.recipeTypes.map(
              (recipeType) => recipeType.name
            ),
            cuisine: recipe.cuisine.name,
            ingredients: recipe.ingredients.map((ingredient) => ({
              foodItem: {
                name: ingredient.foodItem.name
              },
              amount: ingredient.amount,
              unit: ingredient.unit
            })),
            image: image,
          },
        },
      });
      scrollToTop();
    });
  };

  const handleOnDeleteClick = () => {
    setShowWarningModal(true);
  };

  const handleOnClickDeleteAnyway = () => {
    getAccessTokenSilently()
      .then((token) => {
        deleteRecipe({ id: elem.id, token })
          .unwrap()
          .then((_) => {
            setShowWarningModal(false);
            setShowSuccessModal(true);
          })
          .catch((_) => {
            setShowWarningModal(false);
            setShowErrorModal(true);
          });
      })
      .catch((_) => {
        setShowWarningModal(false);
        setShowErrorModal(true);
      });
  };

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        header="Ooops"
      >
        It isn't you. It is us. We couldn't complete your operation. Don't worry
        try later again.
      </ErrorModal>
      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        header="Done"
      >
        <p>
          You successfully completed the operation. Kudos to you that you keep
          all your data up to date.
        </p>
      </SuccessModal>
      <WarningModal
        show={showWaringModal}
        onHide={() => setShowWarningModal(false)}
        header="Are you sure?"
        modalButtonProps={{
          variant: "danger",
          modalButtonText: "Delete anyway",
          onModalButtonClick: handleOnClickDeleteAnyway,
        }}
      >
        <p>
          Just that you know ¡Qué rico! is proud of every recipe that you
          uploaded. But still it is your decision. Are you sure you want to
          delete "{elem.name}"? You can't revert that operation.
        </p>
      </WarningModal>
      <Card className="h-100">
        <FixedSizeCardImage
          src={`${backendBaseUrl}/image/${elem.imageId}`}
          onClick={() => handleOnGoToClick(elem)}
        />
        <Card.Body>
          <Card.Title>{elem.name}</Card.Title>
          <Card.Text>{elem.description}</Card.Text>
        </Card.Body>
        {!isEditMode && (
          <Button
            className="w-50 m-3"
            variant="primary"
            onClick={() => handleOnGoToClick(elem)}
          >
            Go to recipe
          </Button>
        )}
        {isEditMode && (
          <Container className="my-3 px-3">
            <Row>
              <Col>
                <Button
                  className="w-100"
                  variant="primary"
                  onClick={() => handleOnGoToClick(elem)}
                >
                  <OpenInNewIcon />
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100"
                  variant="success"
                  onClick={() => handleOnEditClick(elem)}
                >
                  <EditIcon />
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100"
                  variant="danger"
                  onClick={handleOnDeleteClick}
                >
                  <DeleteIcon />
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Card>
    </>
  );
};

export default RecipeListItem;
