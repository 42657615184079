import { Container, Row } from "react-bootstrap";
import QuestionMark from "@mui/icons-material/QuestionMark";
import { muiColor } from "../../shared/constants";
import GeneralModal from "./GeneralModal";
import { ModalProps } from "../../shared/components/modalProps";

const WarningModal = ({
  show,
  onHide,
  header,
  modalButtonProps,
  children,
}: ModalProps) => (
  <GeneralModal
    show={show}
    onHide={onHide}
    header={header}
    modalButtonProps={modalButtonProps}
  >
    <Container className="text-center">
      <Row>
        <div>
          <QuestionMark sx={{ fontSize: 100, color: muiColor }} />
        </div>
      </Row>
      <Row className="mt-3">{children}</Row>
    </Container>
  </GeneralModal>
);

export default WarningModal;
