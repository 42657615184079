import { Container, Row, Col, Image } from "react-bootstrap";
import bowl from "../../images/bowl.png";
import bowlHalf from "../../images/bowl-half.png";
import Explanation from "./Explanation";

type DescriptionProps = {
  className?: string;
};

const Description = ({ className }: DescriptionProps) => (
  <Container fluid className={`${className}`}>
    <Row>
      <Col className="p-0 d-none d-sm-block">
        <Explanation className="w-100 h-100" />
      </Col>
      <Col className="p-0 d-none d-md-block">
        <Image src={bowl} className="w-100" />
      </Col>
      <Col className="p-0 d-none d-sm-block d-md-none">
        <Image src={bowlHalf} className="w-100" />
      </Col>
    </Row>
    <Row>
      <Col className="p-0 d-block d-sm-none">
        <Explanation className="w-100" />
      </Col>
    </Row>
    <Row>
      <Col className="p-0 d-block d-sm-none">
        <Image src={bowl} className="w-100" />
      </Col>
    </Row>
  </Container>
);

export default Description;
