import { useAuth0 } from "@auth0/auth0-react";
import CenteredSpinner from "../general/CenteredSpinner";
import UserData from "./UserDataContent";
import InternalServerError from "../errors/InternalServerError";
import UnauthorizedError from "../errors/UnauthorizedError";
import UserDataHeader from "./UserDataHeader";

const ProfilePage = () => {
  const { user, isAuthenticated, isLoading, error } = useAuth0();

  return (
    <>
      <UserDataHeader />
      {isLoading && !error && <CenteredSpinner />}
      {!isLoading && error && <InternalServerError />}
      {!isLoading && !error && !isAuthenticated && <UnauthorizedError />}
      {!isLoading && !error && isAuthenticated && user && (
        <UserData user={user} />
      )}
    </>
  );
};

export default ProfilePage;
