export enum Unit {
  g = "g",
  kg = "kg",
  ml = "ml",
  l = "l",
  floz = "floz",
  pt = "pt",
  oz = "oz",
  lb = "lb",
  st = "st",
  piece = "piece",
  pinch = "pinch",
  dash = "dash"
};