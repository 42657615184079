import { Container, Table } from "react-bootstrap";

const Roadmap = () => (
  <Container className="mt-5">
    <h3>Do you know our roadmap?</h3>
    <hr />
    <p>
      ¡Qué rico! will not be a finished product tomorrow. But it will be one
      step further being one tomorrow. We want you to be part of this
      development and we will keep you informed. Below you can read about the
      successes we have already celebrated and what steps we will take next:
    </p>
    <Table className="mt-5 mb-5">
      <thead>
        <tr>
          <th>Date</th>
          <th>Goal</th>
          <th>Description</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2nd of May 2023</td>
          <td>My recipes are editable</td>
          <td>
            It is possible for me to edit and delete the recipes I have created.
          </td>
          <td>Done</td>
        </tr>
        <tr>
          <td>30th of June 2023</td>
          <td>Set servings in recipes</td>
          <td>
            Instead of calculating the quantities manually, you should configure
            directly in the recipe how many people you want to cook for.
          </td>
          <td>Done</td>
        </tr>
        <tr>
          <td>30th of June 2023</td>
          <td>Having a roadmap</td>
          <td>
            All our users are updated about our progress via an roadmap. It is
            clear which steps we are taking next.
          </td>
          <td>Done</td>
        </tr>
        <tr>
          <td>21st of July 2023</td>
          <td>Set default parameters in profile</td>
          <td>
            Make it possible to set the preferred measurement system, your
            author name and your prefered servings amount in your profile.
          </td>
          <td>Not started yet</td>
        </tr>
        <tr>
          <td>15th of August 2023</td>
          <td>Offer ¡Qué rico! in multiple languaes</td>
          <td>
            Currently ¡Qué rico! is only available in English. We want to offer
            Spanish, English and German.
          </td>
          <td>Not started yet</td>
        </tr>
        <tr>
          <td>15th of August 2023</td>
          <td>Show nutrition score of recipes</td>
          <td>
            To go one step more into the direction fo becoming a platform for
            nutrition plans offer a detailed listing of nutrition scores of a
            recipe.
          </td>
          <td>Not started yet</td>
        </tr>
        <tr>
          <td>30th of September 2023</td>
          <td>Create diet plans on ¡Qué rico!</td>
          <td>
            It is possible to create diet plans on ¡Qué rico! via an easy
            process.
          </td>
          <td>Not started yet</td>
        </tr>
      </tbody>
    </Table>
  </Container>
);

export default Roadmap;
