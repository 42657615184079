import { LinkContainer } from "react-router-bootstrap";
import GeneralError from "./GeneralError";
import { Button } from "react-bootstrap";

const InternalServerError = () => (
  <GeneralError
    errorCode="500"
    headline="Ooops!"
    teaser="Currently not reachable."
    detailedDescription="Don't worry it isn't you. It is us. We will be back soon."
  >
    <LinkContainer to="/">
      <Button>Go Home</Button>
    </LinkContainer>
  </GeneralError>
);

export default InternalServerError;
