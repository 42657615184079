import { Container, Row, Col, Image } from "react-bootstrap";
import spagelSmall from "../../images/spagel-small.png";
import spagelBig from "../../images/spagel-big.jpg";
import Quote from "./Quote";

type StatementProps = {
  className?: string;
}

const Statement = ({ className }: StatementProps) => (
  <Container fluid className={`${className}`}>
    <Row>
      <Col className="p-0 d-none d-md-block">
        <Image src={spagelSmall} className="w-100" />
      </Col>
      <Col className="p-0 d-none d-md-block">
        <Quote className="w-100 h-100" />
      </Col>
    </Row>
    <Row>
      <Col className="p-0 d-block d-md-none">
        <Quote className="m-3" />
      </Col>
    </Row>
    <Row>
      <Col className="p-0 d-block d-md-none">
        <Image src={spagelBig} className="w-100" />
      </Col>
    </Row>
  </Container>
);

export default Statement;
