import { useAuth0 } from "@auth0/auth0-react";
import UnauthorizedError from "../../errors/UnauthorizedError";
import MyRecipesHeader from "./MyRecipesHeader";
import CenteredSpinner from "../../general/CenteredSpinner";
import InternalServerError from "../../errors/InternalServerError";
import MyRecipesContent from "./MyRecipesContent";

const MyRecipes = () => {
  const { isLoading, isAuthenticated, error, user } = useAuth0();

  return (
    <>
      <MyRecipesHeader />
      {isLoading && !error && <CenteredSpinner />}
      {!isLoading && error && <InternalServerError />}
      {!isLoading && !error && !isAuthenticated && <UnauthorizedError />}
      {!isLoading && !error && isAuthenticated && (!user || !user.sub) && (
        <InternalServerError />
      )}
      {!isLoading && !error && isAuthenticated && user && user.sub && (
        <MyRecipesContent externalUserId={user.sub} />
      )}
    </>
  );
};

export default MyRecipes;
