import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "../components/home/HomePage";
import ImprintPage from "../components/about/imprint/ImprintPage";
import AboutUsPage from "../components/about/about-us/AboutUsPage";
import AddRecipePage from "../components/cookbook/add-recipe/AddRecipePage";
import RootLayout from "../components/root/RootLayout";
import RootRoutes from "./routes";
import InternalServerError from "../components/errors/InternalServerError";
import ProfilePage from "../components/profile/ProfilePage";
import AllRecipesPage from "../components/cookbook/all-recipes/AllRecipesPage";
import MyRecipesPage from "../components/cookbook/my-recipes/MyRecipesPage";
import EditRecipePage from "../components/cookbook/edit-recipe/EditRecipePage";
import AllRecipesItemPage from "../components/cookbook/all-recipes/item/AllRecipesItemPage";
import MyRecipesItemPage from "../components/cookbook/my-recipes/item/MyRecipesItemPage";

const router = createBrowserRouter([
  {
    path: RootRoutes.Index,
    element: <RootLayout />,
    errorElement: <InternalServerError />,
    id: "root",
    children: [
      { index: true, element: <HomePage /> },
      { path: RootRoutes.AllRecipes, element: <AllRecipesPage /> },
      { path: RootRoutes.AllRecipesItem, element: <AllRecipesItemPage /> },
      { path: RootRoutes.MyRecipesItem, element: <MyRecipesItemPage /> },
      { path: RootRoutes.AddRecipe, element: <AddRecipePage /> },
      { path: RootRoutes.MyRecipes, element: <MyRecipesPage /> },
      { path: RootRoutes.AboutUs, element: <AboutUsPage /> },
      { path: RootRoutes.Imprint, element: <ImprintPage /> },
      { path: RootRoutes.Profile, element: <ProfilePage /> },
      { path: RootRoutes.EditRecipeItem, element: <EditRecipePage /> }
    ],
  },
]);

const RootRouterProvider = () => <RouterProvider router={router} />;

export default RootRouterProvider;
