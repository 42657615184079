import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { IngredientProps } from "../../../shared/components/ingredientProps";
import { FormControlElement } from "../../../shared/components/formControlElement";
import { metricUnits } from "../../../shared/constants";

type IngredientInputProps = {
  className: string;
  label: string;
  buttonText: string;
  placeholderIngredient: string;
  placeholderAmount: string;
  description: string;
  fieldName: string;
  values: IngredientProps[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isInvalid: boolean;
  isValid: boolean | undefined;
  feedback?: string | never[] | string[] | undefined;
};

const ListInput = ({
  className,
  label,
  buttonText,
  placeholderIngredient,
  placeholderAmount,
  description,
  fieldName,
  values,
  setFieldValue,
  isInvalid,
  isValid,
  feedback,
}: IngredientInputProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [currentIngredient, setCurrentIngredient] = useState<string>("");
  const [currentAmount, setCurrentAmount] = useState<string>("");
  const [currentUnit, setCurrentUnit] = useState<string>(metricUnits[0].units[0]);

  const setDisabledBasedOnInput = (
    ingredient: string,
    amount: string,
    unit: string
  ): void => {
    if (ingredient.length === 0 || amount.length === 0 || unit.length === 0) {
      setDisabled(true);
    }

    if (ingredient.length > 0 && amount.length > 0 && unit.length > 0) {
      setDisabled(false);
    }
  };

  const currentIngredientOnChange = (
    event: React.ChangeEvent<FormControlElement>
  ): void => {
    setDisabledBasedOnInput(event.target.value, currentAmount, currentUnit);
    setCurrentIngredient(event.target.value);
  };

  const currentAmountOnChange = (
    event: React.ChangeEvent<FormControlElement>
  ): void => {
    setDisabledBasedOnInput(currentIngredient, event.target.value, currentUnit);
    setCurrentAmount(event.target.value);
  };

  const currentUnitOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setDisabledBasedOnInput(
      currentIngredient,
      currentAmount,
      event.target.value
    );
    setCurrentUnit(event.target.value);
  };

  const onClickAdd = (): void => {
    setFieldValue(fieldName, [
      ...values,
      {
        foodItem: {
          name: currentIngredient,
        },
        amount: currentAmount,
        unit: currentUnit,
      },
    ]);
    setCurrentIngredient("");
    setCurrentAmount("");
    setCurrentUnit(metricUnits[0].units[0]);
    setDisabled(true);
  };

  const onClickRemove = (index: Number): void => {
    setFieldValue(
      fieldName,
      values.filter((_, i) => i !== index)
    );
  };

  const ingredientNameField = (
    <Form.Control
      type="text"
      placeholder={placeholderIngredient}
      value={currentIngredient}
      onChange={(event) => currentIngredientOnChange(event)}
      isValid={isValid}
      isInvalid={isInvalid}
    />
  );
  const ingredientAmountField = (
    <Form.Control
      type="number"
      min="0"
      placeholder={placeholderAmount}
      value={currentAmount}
      onChange={(event) => currentAmountOnChange(event)}
      isValid={isValid}
      isInvalid={isInvalid}
    />
  );
  const ingredientUnitField = (
    <Form.Select
      value={currentUnit}
      onChange={(event) => currentUnitOnChange(event)}
      isValid={isValid}
      isInvalid={isInvalid}
    >
      {[...metricUnits].map((unitType, index) => {
        return (
          <optgroup key={index} label={unitType.type}>
            {[...unitType.units].map((unit, index) => {
              return (
                <option key={index} label={unit}>
                  {unit}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </Form.Select>
  );
  const ingredientDescription = (
    <>
      <Form.Text className="text-muted">{description}</Form.Text>
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </>
  );
  const ingredientButton = (
    <Button onClick={onClickAdd} disabled={disabled} className="w-100">
      {buttonText}
    </Button>
  );

  return (
    <Form.Group controlId={fieldName} className={className}>
      <Form.Label>{label}</Form.Label>
      <Container className="p-0">
        {values.map((ingredient, index) => (
          <Row key={index}>
            <Col md={11} className="mb-3">
              <Form.Control
                type="text"
                value={
                  ingredient.foodItem.name +
                  " - " +
                  ingredient.amount +
                  " " +
                  ingredient.unit
                }
                readOnly
                disabled
              />
            </Col>
            <Col md={1} className="mb-3">
              <Button
                className="w-100"
                variant="danger"
                onClick={() => onClickRemove(index)}
              >
                -
              </Button>
            </Col>
          </Row>
        ))}
        <Row>
          <Col md={5} className="d-none d-md-block">
            {ingredientNameField}
            {ingredientDescription}
          </Col>
          <Col md={2} className="d-none d-md-block">
            {ingredientAmountField}
          </Col>
          <Col md={2} className="d-none d-md-block">
            {ingredientUnitField}
          </Col>
          <Col md={3} className="d-none d-md-block">
            {ingredientButton}
          </Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none mb-3">{ingredientNameField}</Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none mb-3">{ingredientAmountField}</Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none mb-3">
            {ingredientUnitField}
            {ingredientDescription}
          </Col>
        </Row>
        <Row>
          <Col className="d-block d-md-none">{ingredientButton}</Col>
        </Row>
      </Container>
    </Form.Group>
  );
};

export default ListInput;
