import { useLocation, useParams } from "react-router-dom";
import LoadedRecipe from "../../recipe/LoadedRecipe";
import RootRoutes from "../../../../navigation/routes";
import InternalServerError from "../../../errors/InternalServerError";

const MyRecipesItemPage = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <>
      {id && (
        <LoadedRecipe
          id={id}
          backlink={{ name: "My recipes", path: RootRoutes.MyRecipes }}
          recipeNewlyAdded={location?.state?.recipeNewlyCreated}
        />
      )}
      {!id && <InternalServerError />}
    </>
  );
};

export default MyRecipesItemPage;
