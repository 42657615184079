import Breadcrumb from "react-bootstrap/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";
import { Path } from "react-router-dom";

type Step = {
  path?: string;
  name: string;
  active: boolean;
};

type NavigationProps = {
  steps: Step[];
};

const convertToPath = (uri: string): Path => {
  const url = new URL(uri, window.location.origin);
  return {
    hash: url.hash,
    pathname: url.pathname,
    search: url.search,
  };
};

const Navigation = ({ steps }: NavigationProps) => (
  <Breadcrumb>
    {steps.map((step, index) =>
      step.path ? (
        <LinkContainer to={convertToPath(step.path)} key={index}>
          <Breadcrumb.Item active={step.active}>{step.name}</Breadcrumb.Item>
        </LinkContainer>
      ) : (
        <Breadcrumb.Item active={step.active} key={index}>
          {step.name}
        </Breadcrumb.Item>
      )
    )}
  </Breadcrumb>
);

export default Navigation;
