import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { FormikErrors, FormikTouched } from "formik";
import { RecipeProps } from "../../../shared/components/recipeProps";

type RecipeGeneralFormProps = {
  values: RecipeProps;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  errors: FormikErrors<RecipeProps>;
  touched: FormikTouched<RecipeProps>;
};

const RecipeGeneralForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}: RecipeGeneralFormProps) => (
  <>
    <Row>
      <h4>General</h4>
    </Row>
    <Row>
      <Form.Group controlId="name" className="mt-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.name}
          isValid={touched.name && !errors.name}
        />
        <Form.Text className="text-muted">
          Give your recipe a descriptive name.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row>
      <Form.Group controlId="description" className="mt-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Be creative about your recipe."
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.description}
          isValid={touched.description && !errors.description}
        />
        <Form.Text className="text-muted">
          This text will appear in the overview section as description.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Form.Group>
    </Row>
  </>
);

export default RecipeGeneralForm;
